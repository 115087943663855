import { useEffect, useState } from "react";

const useBooks = () => {
  const [books, setBooks] = useState([]);
  useEffect(() => {
    fetch("https://nameless-retreat-76117.herokuapp.com/book")
      .then((res) => res.json())
      .then((data) => setBooks(data));
  }, []);
  return [books, setBooks];
};
export default useBooks;

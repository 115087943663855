import React from "react";
import { Card } from "react-bootstrap";
import useBooks from "../../hooks/useBooks";

const AllBooks = () => {
  const [books, setBooks] = useBooks();
  const handleDelete = (id) => {
    console.log(id);
    const proceed = window.confirm("Are You sure?");
    if (proceed) {
      const url = `https://nameless-retreat-76117.herokuapp.com/book/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          const remaining = books.filter((book) => book._id !== id);
          setBooks(remaining);
        });
    }
  };

  return (
    <div className="w-50 mx-auto">
      <h1>Manage Your Book</h1>
      {books.map((book) => (
        <div key={ServiceWorker._id}>
          <h4>{book.name}</h4>
          <div class="col-md-4">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={book.img} />
              <Card.Body>
                <Card.Title>{book.name}</Card.Title>
                <Card.Text>${book.price}</Card.Text>
                <Card.Text>
                  <b>Instock : {book.quantity}</b>
                </Card.Text>
                <Card.Text>{book.description}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllBooks;

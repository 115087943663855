import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p className='text-center mt-10'><small>Copyright @ 2022</small></p>
        </footer>
    );
};

export default Footer;
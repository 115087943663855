import { useEffect, useState } from "react";

const useSingleBookDetails = (id) => {
  const [book, setBook] = useState([]);

  useEffect(() => {
    fetch(`https://nameless-retreat-76117.herokuapp.com/book/${id}`)
      .then((res) => res.json())
      .then((data) => setBook(data));
  }, [id]);

  return [book, setBook];
};

export default useSingleBookDetails;
